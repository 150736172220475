<template>
    <div class="aops-pull-new-upload">
        <img v-if="uploadImageUrl" class="aop-upload-img" :src="uploadImageUrl"  alt=""/>
        <el-upload
            accept=".jpg, .jpeg, .png, .apng, .gif, .bmp"
            action="#"
            :auto-upload="false"
            :on-change="handleImage"
            :show-file-list="false"
        >
            <el-button type="primary" :loading="loading" class="aop-confirm-button">{{uploadImageUrl ? '重新上传' : '上传'}}</el-button>
            <span slot="tip" class="el-upload__tip">{{uploadConfig[prop].tip}}</span>
        </el-upload>
    </div>
</template>

<script>
import { uploadConfig } from './data';
import uploadImg from '@/mixins/upload-img';

export default {
    mixins: [uploadImg],
    props: {
        prop: {
            type: String,
            default: 'activity_banner'
        },
        uploadImageUrl: {
            type: String,
            default: ''
        }
    },
    /**
     * data
     * @returns {*}
    */
    data() {
        return {
            uploadConfig,
            loading: false
        };
    },
    methods: {
        /**
         * 上传图片
         * @param {*} img img
         * @returns {*}
        */
        asyncImageOnload(img) {
            return new Promise((resolve) => {
                img.onload = () => {
                    resolve();
                };
            });
        },
        /**
         * 上传图片
         * @param {*} file file
         */
        async handleImage(file) {
            this.loading = true;
            const config = this.uploadConfig[this.prop];
            const fileRaw = file.raw;
            const path = window.URL.createObjectURL(fileRaw);
            const img = new Image();
            img.src = path;
            await this.asyncImageOnload(img);
            if (img.width < config.width || img.height < config.height || ((img.height / config.height) !== img.width / config.width)) {
                this.$$error(`请上传尺寸为${config.width}*${config.height}或其倍数的图片`);
                this.loading = false;
                return;
            }
            this.uploadImg(file);
        },
        /**
         * 上传图片
         * @param {File} file 图片
         */
        uploadImg(file) {
            this.handleUploadImg(file).then((res) => {
                // if (res.data.data) {
                //     this.$emit('onChange', { prop: this.prop, img: res.data.data.url });
                // } else {
                //     this.$Message.error('Image upload error');
                // }
                this.$emit('onChange', { prop: this.prop, img: res.url });
                this.loading = false;
            }, () => {
                this.loading = false;
            });
        }
    }
};
</script>
