<template>
    <div class="aops-pull-new-activity">
        <div class="aop-back" @click="handleBack">
            <i class="iconfont aop-back-icon">&#xe647;</i><span>返回</span>
        </div>
        <div ref="activityIntro" v-loading="isLoading" class="aop-activity-intro-wrap">
            <div class="aop-activity-title">活动介绍</div>
            <div v-for="item in langData" :key="item.type" class="aop-activity-intro">
                <div class="aop-lang-text">{{item.text}}</div>
                <el-form
                    :ref="item.ref"
                    :model="form[item.prop]"
                    :rules="rules[item.prop]"
                    label-width="93px"
                >
                    <activity-content
                        :lang="item.type"
                        :form="form[item.prop]"
                        @onPropValid="(prop) => handlePropValid(prop, item.ref)"
                    />
                </el-form>
            </div>
        </div>

        <div class="aop-activity-footer">
            <el-button class="cancel-button" :loading="previewBtnLoading" @click="handlePreview">预览</el-button>
            <el-button type="primary" class="aop-confirm-button" :loading="btnLoading" @click="handleConfirm">保存并使用</el-button>
        </div>
  </div>
</template>

<script>
import env from 'env';
import Api from '@/api/promotions';
import activityContent from './content/content.vue';
import { langData, initForm, rules } from './data';

export default {
    components: { activityContent },
    /**
     * data
     * @returns {*}
    */
    data() {
        return {
            langData,
            form: { ...initForm },
            rules,
            isLoading: false, // 获取活动介绍数据loading
            btnLoading: false, // 确认按钮loading
            previewBtnLoading: false // 预览按钮loading
        };
    },
    /**
     * mounted
    */
    mounted() {
        this.getActivityInfo();
    },

    methods: {
        /**
         * 确认提交
         */
        getActivityInfo () {
            this.isLoading = true;
            Api.getActivityInfo().then((res) => {
                if (res.data.code === 10200) {
                    const { activity_introduce, activity_introduce_en } = res.data.data;
                    this.form = {
                        activity_introduce,
                        activity_introduce_en
                    };
                } else {
                    this.$$error(res.data.message);
                }
                this.isLoading = false;
                this.$nextTick(() => {
                    // 滚动到顶部
                    this.$refs.activityIntro.scrollTop = 0;
                });
            }).catch(() => {
                this.isLoading = false;
            });
        },
        /**
         * 返回
        */
        handleBack() {
            this.$router.push('/promotions/pull-new-management');
        },
        /**
         * 单个字段校验
         * @param {string} prop 字段
         * @param {*} ref ref
        */
        handlePropValid(prop, ref) {
            this.$refs[ref][0].validateField([prop]);
        },
        /**
         * form 校验
         * @returns {Boolean}
        */
        handleValid() {
            let res = false;
            let enRes = false;
            this.$refs.form[0].validate((valid) => {
                if (valid) { res = true; }
            });
            this.$refs.formEn[0].validate((valid) => {
                if (valid) { enRes = true; }
            });
            if (!res || !enRes) {
                this.handleScrollView();
            }
            return res && enRes;
        },
        /**
         * 点击确认按钮
        */
        handleConfirm() {
            const valid = this.handleValid();
            if (!valid) { return; }
            this.btnLoading = true;
            Api.activityEdit({
                model: 1,
                ...this.form
            }).then((res) => {
                if (res.data.code === 10200) {
                    this.$$success('活动介绍保存成功');
                    this.handleBack();
                } else {
                    this.$$error(res.data.message);
                }
                this.btnLoading = false;
            }).catch(() => {
                this.btnLoading = false;
            });
        },
        /**
         * 点击预览  model =1  线上真实数据。不存则默认为1 model =2 表示获取预览数据
        */
        handlePreview() {
            const valid = this.handleValid();
            if (!valid) { return; }
            this.previewBtnLoading = true;
            Api.activityEdit({
                model: 2,
                ...this.form
            }).then((res) => {
                if (res.data.code === 10200) {
                    // 跳转pbrmax预览页面
                    window.open(`${env.webCn}registration-view`);
                } else {
                    this.$$error(res.data.message);
                }
                this.previewBtnLoading = false;
            }).catch(() => {
                this.previewBtnLoading = false;
            });
        },
        /**
         * 滚动到报错位置
        */
        handleScrollView() {
            this.$nextTick(() => {
                const isError = document.getElementsByClassName('is-error');
                // 滚动到指定节点
                isError[0].scrollIntoView({
                    block: 'center',
                    behavior: 'smooth'
                });
            });
        }
    }
};
</script>
<style lang="less">
@import "./activity.less";
</style>
