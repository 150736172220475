// 图文编辑器配置
export const textImgOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    [{ align: [] }],
    ['link', 'image', 'video']
];

export const maxSize = {
    cn: 500,
    en: 1500
};

// 图文内容--中文/英文placeholder
export const contPlaceholder = {
    cn: `请输入内容，${maxSize.cn}字以内`,
    en: `请输入内容，${maxSize.en}字以内`
};
