export const langData = [{
    type: 'cn',
    text: '中文',
    prop: 'activity_introduce',
    ref: 'form'
}, {
    type: 'en',
    text: '英文',
    prop: 'activity_introduce_en',
    ref: 'formEn'
}];
export const initForm = {
    activity_introduce: { // 介绍
        activity_title: '', // 标题中文
        activity_banner: '', // 活动入口图
        activity_desc: '', // 活动描述
        activity_link_desc: '', // 邀请链接介绍
        activity_code_desc: '', // 活动邀请码介绍
        activity_poster: '', // 活动海报图
        activity_register_pop: '', // 注册成功弹窗
        activity_award_title: '', // 活动奖励标题
        activity_award_desc: '', // 活动奖励描述
        activity_award_pic: '' // 活动奖励图片
    },
    activity_introduce_en: { // 介绍英文
        activity_title: '', // 标题中文
        activity_banner: '', // 活动入口图
        activity_desc: '', // 活动描述
        activity_link_desc: '', // 邀请链接介绍
        activity_code_desc: '', // 活动邀请码介绍
        activity_poster: '', // 活动海报图
        activity_register_pop: '', // 注册成功弹窗'
        activity_award_title: '', // 活动奖励标题
        activity_award_desc: '', // 活动奖励描述
        activity_award_pic: '' // 活动奖励图片
    }
};
const imgRules = {
    activity_banner: [
        { required: true, message: '请上传活动入口图', trigger: 'change' }
    ],
    activity_poster: [
        { required: true, message: '请上传活动海报图', trigger: 'change' }
    ],
    activity_register_pop: [
        { required: true, message: '请上传注册成功弹窗', trigger: 'change' }
    ],
    activity_award_pic: [
        { required: true, message: '请上传奖励描述图片', trigger: 'change' }
    ]
};
export const rules = {
    activity_introduce: {
        ...imgRules,
        activity_title: [
            { required: true, message: '请输入标题', trigger: 'blur' },
            { min: 1, max: 30, message: '最多可输入30个字', trigger: 'blur' }
        ],
        activity_desc: [
            { required: true, message: '请输入活动介绍', trigger: 'blur' }
            // { min: 1, max: 500, message: '最多可输入500个字', trigger: 'blur' }
        ],
        activity_link_desc: [
            { required: true, message: '请输入邀请链接介绍', trigger: 'blur' },
            { min: 1, max: 120, message: '最多可输入120个字', trigger: 'blur' }
        ],
        activity_code_desc: [
            { min: 0, max: 120, message: '最多可输入120个字', trigger: 'blur' }
        ],
        activity_award_title: [
            { required: true, message: '请输入奖励描述的标题', trigger: 'blur' },
            { min: 1, max: 30, message: '最多可输入30个字', trigger: 'blur' }
        ],
        activity_award_desc: [
            { required: true, message: '请输入奖励描述正文', trigger: 'blur' }
            // { min: 1, max: 500, message: '最多可输入500个字', trigger: 'blur' }
        ]
    },
    activity_introduce_en: {
        ...imgRules,
        activity_title: [
            { required: true, message: '请输入标题', trigger: 'blur' },
            { min: 1, max: 90, message: '最多可输入90个字', trigger: 'blur' }
        ],
        activity_desc: [
            { required: true, message: '请输入活动介绍', trigger: 'blur' }
            // { min: 1, max: 1500, message: '最多可输入1500个字', trigger: 'blur' }
        ],
        activity_link_desc: [
            { required: true, message: '请输入邀请链接介绍', trigger: 'blur' },
            { min: 1, max: 360, message: '最多可输入360个字', trigger: 'blur' }
        ],
        activity_code_desc: [
            { min: 0, max: 360, message: '最多可输入360个字', trigger: 'blur' }
        ],
        activity_award_title: [
            { required: true, message: '请输入奖励描述的标题', trigger: 'blur' },
            { min: 1, max: 90, message: '最多可输入90个字', trigger: 'blur' }
        ],
        activity_award_desc: [
            { required: true, message: '请输入奖励描述正文', trigger: 'blur' }
            // { min: 1, max: 1500, message: '最多可输入1500个字', trigger: 'blur' }
        ]
    }
};

export const uploadConfig = {
    activity_banner: {
        tip: '请上传图片（305* 80）',
        width: 305,
        height: 80
    },
    activity_poster: {
        tip: '请上传图片（1125* 2100）',
        width: 1125,
        height: 2100
    },
    activity_award_pic: {
        tip: '请上传图片（685* 600）',
        width: 685,
        height: 600
    },
    activity_register_pop: {
        tip: '请上传图片（890* 587）',
        width: 890,
        height: 587
    }
};
