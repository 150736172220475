<template>
    <div class="aops-pull-new-activity-content">
        <el-form-item label="活动入口图" prop="activity_banner">
            <uploadImg prop="activity_banner" :upload-image-url="form.activity_banner" @onChange="handleImgChange" />
        </el-form-item>
        <el-form-item
            label="标题"
            prop="activity_title"
        >
            <el-input
                v-model="form.activity_title"
                placeholder="请输入标题"
            />
        </el-form-item>
        <el-form-item
            class="aop-textEditor"
            label="活动介绍"
            prop="activity_desc"
        >
            <quill-editor
                :form="form"
                :lang="lang"
                prop="activity_desc"
                @change="handleValidate('activity_desc')"
            />
        </el-form-item>
        <el-form-item
            label="邀请链接介绍"
            prop="activity_link_desc"
        >
            <el-input
                v-model="form.activity_link_desc"
                placeholder="请输入邀请链接介绍"
            />
        </el-form-item>
        <el-form-item
            label="邀请码介绍"
            prop="activity_code_desc"
        >
            <el-input
                v-model="form.activity_code_desc"
                placeholder="请输入邀请码介绍"
            />
        </el-form-item>
        <el-form-item
            label="活动海报"
            prop="activity_poster"
        >
            <uploadImg prop="activity_poster" :upload-image-url="form.activity_poster" @onChange="handleImgChange" />
        </el-form-item>
        <el-form-item
            label="奖励描述标题"
            prop="activity_award_title"
        >
            <el-input
                v-model="form.activity_award_title"
                placeholder="请输入奖励描述标题"
            />
        </el-form-item>
        <el-form-item
            class="aop-textEditor"
            label="奖励描述正文"
            prop="activity_award_desc"
        >
            <quill-editor
                :form="form"
                :lang="lang"
                prop="activity_award_desc"
                @change="handleValidate('activity_award_desc')"
            />
        </el-form-item>
        <el-form-item
            label="奖励描述图片"
            prop="activity_award_pic"
        >
            <uploadImg
                :upload-image-url="form.activity_award_pic"
                prop="activity_award_pic"
                @onChange="handleImgChange"
            />
        </el-form-item>
        <el-form-item
            label="注册成功弹窗"
            prop="activity_register_pop"
        >
            <uploadImg prop="activity_register_pop" :upload-image-url="form.activity_register_pop"  @onChange="handleImgChange"/>
        </el-form-item>
    </div>
</template>

<script>
import uploadImg from '../upload';
import quillEditor from '../quillEditor/quillEditor.vue';

export default {
    components: { quillEditor, uploadImg },
    props: {
        form: {
            type: Object,
            default: () => ({})
        },
        lang: {
            type: String,
            default: 'cn'
        }
    },
    /**
     * data
     * @returns {*}
    */
    data() {
        return {
            uploadImageUrl: ''
        };
    },
    methods: {
        /**
         * 处理图片上传，更改form
         * @param {*} param {prop, img}
         */
        handleImgChange(param) {
            this.form[param.prop] = param.img;
        },
        /**
         * 编辑器校验
         * @param {*} prop 字段
        */
        handleValidate(prop) {
            this.$emit('onPropValid', prop);
        }
    }
};
</script>
<style lang="less">
@import "./content.less";
</style>
