<template>
    <div class="aops-quill-editor">
        <el-upload
            ref="uploader"
            class="aop-avatar-uploader"
            accept=".jpg, .jpeg, .png, .apng, .gif, .bmp"
            name="img"
            action="#"
            :auto-upload="false"
            :on-change="handleUpImg"
            :show-file-list="false"
        />
        <p class="aop-textImg-tip">注意！添加图片需从电脑端本机上传，其他网站上获取的图片存在过期风险！</p>
        <quill-editor
            ref="textQuillEditor"
            v-model="form[prop]"
            class="aop-textImgEdit"
            :options="editImgOption"
            @change="editChange($event)"
        />
        <span class="aop-size-tip">{{ contentLength }}/{{maxSize}}</span>
    </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor';
import uploadImg from '@/mixins/upload-img';
import { textImgOptions, contPlaceholder, maxSize } from './quill-config';

export default {
    components: { quillEditor },
    mixins: [uploadImg],
    props: {
        form: {
            type: Object,
            default: () => ({})
        },
        prop: {
            type: String,
            default: 'activity_desc'
        },
        lang: {
            type: String,
            default: 'cn'
        }
    },
    /**
     * data
     * @returns {*}
    */
    data() {
        return {
            editImgOption: {
                placeholder: contPlaceholder[this.lang],
                modules: {
                    toolbar: {
                        container: textImgOptions,
                        handlers: {
                            image: this.handleEditorImage
                        }
                    }
                }
            },
            maxSize: maxSize[this.lang], // 文本编辑框可输入最大长度
            contentLength: 0 // 文本编辑框已输入的字符长度
        };
    },
    /**
     * 实例渲染
    */
    mounted() {
        const quill = this.$refs.textQuillEditor.quill;
        if (this.form.type === 1 && quill) {
            // 实例重新渲染,仅影响实例当前组件
            this.$forceUpdate();
            // 自定义粘贴图片功能
            quill.root.addEventListener(
                'paste',
                (evt) => {
                    if (evt.clipboardData && evt.clipboardData.files && evt.clipboardData.files.length) {
                        evt.preventDefault();
                        [].forEach.call(evt.clipboardData.files, (file) => {
                            if (!file.type.match(/^image\/(gif|jpe?g|a?png|bmp)/i)) {
                                return;
                            }
                            this.uploadToServer(file);
                        });
                    }
                },
                false
            );
        }
    },
    methods: {
        // /**
        //  * 文本编辑器change
        // */
        // editChange() {
        //     // event.quill.deleteText(this.maxSize, 4);
        //     // if (this.form.activity_desc === '') {
        //     //     this.contentLength = 0;
        //     // } else {
        //     //     this.contentLength = event.quill.getLength() - 1;
        //     // }
        //     // 编辑器校验
        //     this.$emit('change');
        // },
        /**
         * 文本编辑器change
         * @param {*} event event
        */
        editChange(event) {
            event.quill.deleteText(this.maxSize, 4);
            this.$emit('change');
            if (this.form[this.prop] === '') {
                this.contentLength = 0;
            } else {
                this.contentLength = event.quill.getLength() - 1;
            }
        },
        /**
         * 富文本图片处理
         * @param {Boolean} value 值
        */
        handleEditorImage(value) {
            if (value) {
                this.$refs.uploader.$refs['upload-inner'].$refs.input.click();
            } else {
                this.quill.format('image', false);
            }
        },

        /**
         * 上传图片
         * @param {File} file 图片
        */
        handleUpImg(file) {
            if (file.size > 1024 * 1024 * 5) {
                this.$$error('图片过大， 不能超过5M.');
                return;
            }
            this.handleUploadImg(file).then((res) => {
                const quill = this.$refs.textQuillEditor.quill;
                // if (res.data.data) {
                //     const length = this.$refs.textQuillEditor.quill.selection.savedRange.index;
                //     quill.insertEmbed(length, 'image', res.data.data.url);
                //     quill.setSelection(length + 1);
                // } else {
                //     this.$Message.error('Image upload error');
                // }
                const length = this.$refs.textQuillEditor.quill.selection.savedRange.index;
                quill.insertEmbed(length, 'image', res.url);
                quill.setSelection(length + 1);
            });
        },
        /**
         * 修改粘贴图片为上传服务器
         * @param {File} file 图片
        */
        uploadToServer(file) {
            const fileObj = {
                raw: file
            };
            this.handleUpImg(fileObj);
        }
    }
};
</script>
<style lang="less">
@import "./quillEditor.less";
</style>
